import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { SquareTerminal } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { ACTION_STATUSES } from '@/const/const.js'

export default function RunCommandAction({ action, isLast = false }) {
  action = { ...action, status: ACTION_STATUSES.RUNNING }
  console.log('Inside action command', action)
  //:TODO: Fix display of command in the top content in the details
  const terminalOutputs = []
  let detailsContent = `## ACTION:\n${action?.args?.comment}\n\n## COMMAND:\n${action?.args?.command || action?.args?.cmd}\n\n`

  const outputs = action?.outputs
  let didError = false
  if (Array.isArray(outputs)) {
    detailsContent += '## OUTPUTS:\n'
    outputs?.forEach(output => {
      if (typeof output === 'object' && output !== null) {
        const outputEntries = Object.entries(output).map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `- ${entry}\n`
          terminalOutputs.push(`- ${entry}\n`)
        })
        if (!didError) {
          didError = output?.exit_code !== 0 ? true : false
        }
      }
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      terminalOutputs={terminalOutputs}
      actionIcon={SquareTerminal}
      isLast={isLast}
      title="Run command"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentTerminal
          content={'$ ' + action?.args?.cmd}
          terminalOutputs={terminalOutputs}
          errored={didError}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

RunCommandAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
